<template>
  <v-app id="inspire">
    <v-container>
      <top-bar :navpos="items" />
      <v-main>
        <h1>{{  $t("errorPages.forbidden.head")  }}</h1>
        <br /><br />
        <p>{{  $t("errorPages.forbidden.p1")  }}</p>
        <p>{{  $t("errorPages.forbidden.p2")  }}</p>
        <p>{{  $t("errorPages.forbidden.p3")  }}</p>
        <div style="width: 200px; margin: 30px auto 0 auto">
          <pre>
  __           _     _     _     _            
 / _|         | |   (_)   | |   | |           
| |_ ___  _ __| |__  _  __| | __| | ___ _ __  
|  _/ _ \| '__| '_ \| |/ _` |/ _` |/ _ \ '_ \ 
| || (_) | |  | |_) | | (_| | (_| |  __/ | | |
|_| \___/|_|  |_.__/|_|\__,_|\__,_|\___|_| |_|
    
        </pre>
        </div>
      </v-main>
      <footer-bar />
    </v-container>
  </v-app>
</template>
<script>
import TopBar from "../components/TopBar.vue";
import FooterBar from "../components/Footer.vue";

export default {
  components: { TopBar, FooterBar },
  name: "Main",
  props: {},
  data() {
    return {
      items: [
        {
          text: this.$t("nav.main"),
          disabled: false,
          href: "/main"
        }
      ]
    };
  },
  methods: {
  },
  computed: {},
  mounted() {
  }
};
</script>